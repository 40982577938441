import React, { useState } from 'react'
import './InsGroupModal.css'
import ModalsActions from '../../../actions/ModalsActions';
import SimpleButton from '../../Reusable/SimpleButton/SimpleButton';
import CustomDropdown from '../../Reusable/CustomDropdown/CustomDropdown'
import FloatingLableInput from '../../Reusable/FloatingLabelInput/FloatingLableInput';
import CheckboxR from '../../Reusable//Checkbox/CheckboxR'
import AdminActions from '../../../actions/AdminActions';
import ToggleButton from '../../Reusable/ToggleButton/ToggleButton';

const InsGroupModal = ({ groupInfo }) => {
  const judete = ["Alba", "Arad", "Arges", "Bacau", "Bihor", "Bistrita-Nasaud", "Botosani", "Braila", "Brasov", "Bucuresti", "Buzau", "Calarasi", "Caras-Severin", "Cluj", "Constanta", "Covasna", "Dambovita", "Dolj", "Galati", "Giurgiu", "Gorj", "Harghita", "Hunedoara", "Ialomita", "Iasi", "Ilfov", "Maramures", "Mehedinti", "Mures", "Neamt", "Olt", "Prahova", "Salaj", "Satu Mare", "Sibiu", "Suceava", "Teleorman", "Timis", "Tulcea", "Valcea", "Vaslui", "Vrancea"]
  const [updateInfo, setUpdateInfo] = useState({
    GroupId: groupInfo.groupId,
    Judet: '',
    Localitate: '',
    UserId: '',
    SubUserId: '',
    Email: '',
    EmailAlternativ: '',
    UserDF: false,
    Denumire: '',
    MonitorizareZilnica: false,
  })

  const handleCloseClick = () => {
    ModalsActions.hideModal();
  }

  const handleOptionChange = (option) => {
    setUpdateInfo({ ...updateInfo, Judet: option })
  }

  const handleInfoChange = (e) => {
    setUpdateInfo({ ...updateInfo, [e.target.name]: e.target.value })
  }

  const handleCheckboxChange = (e) => {
    setUpdateInfo({ ...updateInfo, UserDF: e.target.checked })
  }

  const handleUpdate = () => {
    AdminActions.updateInsvGroupInfo({ ...updateInfo, Type: groupInfo.type })
    ModalsActions.hideModal();
  }

  const handleAdd = () => {
    AdminActions.addInsvGroup(updateInfo)
    ModalsActions.hideModal();
  }

  const handleToggleChange = () => {
    setUpdateInfo({...updateInfo, MonitorizareZilnica: !updateInfo.MonitorizareZilnica})
  }

  return (
    <div className='modal-dialog topInsGroupModal'>
      <div className={`modal-content ${groupInfo.addGroup ? 'insGroupModalAdd' : 'insGroupModalUpdate'}`}>
        <div className="row closeBtn">
          <button type="button" className="modals-close-button close" onClick={(event) => handleCloseClick(event)}>&times;</button>
        </div>
        {!groupInfo.addGroup
          ? <div className='insActions'>
            {groupInfo.type === 1 && <CustomDropdown options={judete} selectedOption={updateInfo.Judet} placeholder='Selecteaza Judet' handleOptionChange={handleOptionChange} />}
            {groupInfo.type === 2 && <FloatingLableInput label='Localitate' name='Localitate' value={updateInfo.Localitate} placeholder='Alege Localitate' onChange={handleInfoChange} />}
            {groupInfo.type === 3 && <FloatingLableInput type='number' label='User Id' name='SubUserId' value={updateInfo.SubUserId} placeholder='Alege Id User' onChange={handleInfoChange} />}
            {groupInfo.type == 4 &&
              <div>
                <CheckboxR label='User DF' checked={updateInfo.UserDF} onChange={handleCheckboxChange} />
                <FloatingLableInput type='number' label='User Id' name='UserId' value={updateInfo.UserId} placeholder='Alege Id User' onChange={handleInfoChange} />
                <FloatingLableInput label='Email' name='Email' value={updateInfo.Email} placeholder='Alege Email' disabled={updateInfo.UserDF} onChange={handleInfoChange} />
                <FloatingLableInput label='Email Alternativ' name='EmailAlternativ' value={updateInfo.EmailAlternativ} placeholder='Alege Email Alternativ' disabled={updateInfo.UserDF} onChange={handleInfoChange} />
              </div>}
            <SimpleButton text="Salveaza" onClick={handleUpdate} />
          </div>
          : <div className='insAddGroup'>
            <FloatingLableInput label='Denumire Grup' name='Denumire' value={updateInfo.Denumire} placeholder='Alege Denumire' onChange={handleInfoChange} />
            <ToggleButton text='Monitorizare Zilnica' toggled={updateInfo.MonitorizareZilnica} onChange={handleToggleChange} />
            <CustomDropdown options={judete} selectedOption={updateInfo.Judet} placeholder='Selecteaza Judet' handleOptionChange={handleOptionChange} />
            <FloatingLableInput label='Localitate' name='Localitate' value={updateInfo.Localitate} placeholder='Alege Localitate' onChange={handleInfoChange} />
            <FloatingLableInput type='number' label='Subgroup User Id' name='SubUserId' value={updateInfo.SubUserId} placeholder='Alege Id User' onChange={handleInfoChange} />
            <CheckboxR label='User DF' checked={updateInfo.UserDF} onChange={handleCheckboxChange} />
            <FloatingLableInput type='number' label='User Id' name='UserId' value={updateInfo.UserId} placeholder='Alege Id User' disabled={!updateInfo.UserDF} onChange={handleInfoChange} />
            <FloatingLableInput label='Email' name='Email' value={updateInfo.Email} placeholder='Alege Email' disabled={updateInfo.UserDF} onChange={handleInfoChange} />
            <FloatingLableInput label='Email Alternativ' name='EmailAlternativ' value={updateInfo.EmailAlternativ} placeholder='Alege Email Alternativ' disabled={updateInfo.UserDF} onChange={handleInfoChange} />
            <SimpleButton text="Adauga" onClick={handleAdd} />
          </div>}
      </div>
    </div>
  )

}

export default InsGroupModal