import React from 'react'
import './CheckboxR.css'

const CheckboxR = ({ checked, onChange, name, label, className }) => {
    return (
        <div className={className}>
            <label className="checkbox-label">
                <input
                    className="custom-checkbox"
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    name={name}
                />
                <span>{label}</span>
            </label>
        </div>
    )
}

export default CheckboxR