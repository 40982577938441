import './HistoryTable.css'
import React from 'react';
import FileActions from '../../../../../../../actions/FileActions';
import { disableButton } from '../../../../../../../utils/ButtonDisabler';
import { Link } from 'react-router-dom';

const HistoryTable = (props) => {

    const getRequestedReports = (optiuni) => {
        var reportsMsg = ""
        var options = optiuni.split(',').map(nb => { return Number(nb) })
        for (let i = 0; i < options.length; i++) {
            if (options.length === 6) {
                reportsMsg = props.text.All
                break
            }
            else if (options[i] === 1) {
                reportsMsg = props.text.Adm
            }
            else if (options[i] === 2) {
                reportsMsg = reportsMsg + ((reportsMsg === "") ? "" : ", ") + "Financiar"
            }
            else if (options[i] === 3) {
                reportsMsg = reportsMsg + ((reportsMsg === "") ? "" : ", ") + "Legal"
            }
            else if (options[i] === 4) {
                reportsMsg = reportsMsg + ((reportsMsg === "") ? "" : ", ") + "CIP"
            }
            else if (options[i] === 5) {
                reportsMsg = reportsMsg + ((reportsMsg === "") ? "" : ", ") + "GRM"
            }
            else if (options[i] === 6) {
                reportsMsg = reportsMsg + ((reportsMsg === "") ? "" : ", ") + "Licitatii"
            }
            else if (options[i] === 7) {
                reportsMsg = reportsMsg + ((reportsMsg === "") ? "" : ", ") + "BERC"
            }
            else if (options[i] === 0) {
                reportsMsg = reportsMsg + ((reportsMsg === "") ? "" : ", ") + "Stare"
            }
        }
        return reportsMsg
    }

    const downloadRaport = (event, downloadUrl) => {
        FileActions.download(downloadUrl, null, null, disableButton(event.target))
    }

    const refresh = () => {
        window.location.reload(false);
    }

    const getCuiLink = (cuiList, idList) => {
        // var renderLink = "/data/details/company/"
        let cuis = cuiList.split(',')
        let ids = idList.split(',')
        let list = []
        for (let i = 0; i < cuis.length; i++) {
            let lastItem = (i === cuis.length - 1) ? true : false
            let obj = { cui: cuis[i], id: ids[i], last: lastItem, key: i }
            list.push(obj)
        }
        return list.map(obj => { return <Link key={obj.key} to={`/data/details/company/${obj.id}`}>{obj.cui}{obj.last ? "" : ", "}</Link> })
    }

    const istoric = props.istoric.map(item =>
        <tr key={item.InterogareId}>
            <td>{getCuiLink(item.ListaCui, item.ListaFirmeId)}</td>
            <td>{item.DataInterogare.slice(0, 10) + '\xa0\xa0' + item.DataInterogare.slice(11, 16)}</td>
            <td>{getRequestedReports(item.ListOptiuni)}</td>
            <td className="size-row">{item.StatusId === 0
                ? <span className="label label-default">{props.text.Loading}</span>
                : item.StatusId === 1
                    ? <span className="label label-success">{props.text.Loaded}</span>
                    : <span className="label label-primary">{props.text.Downloaded}</span>}
            </td>
            <td>{item.StatusId === 0
                ? <button onClick={refresh}>{props.text.Refresh}</button>
                : <button className={`btn btn-${item.StatusId === 1 ? 'success' : 'primary'}`}
                    onClick={(e) => downloadRaport(e, item.DownloadUrl)}>{item.StatusId === 1 ? props.text.Download : props.text.Again}</button>}
            </td>
        </tr>)


    return (
        <div className='historyTable'>
            <table className='row interogations-advanced-table'>
                <thead className={props.istoric.length < 9 ? "header" : "header scrollable"}>
                    <tr>
                        <th>CUI</th>
                        <th>{props.text && props.text.Data}</th>
                        <th>{props.text && props.text.Options}</th>
                        <th>Status</th>
                        <th>{props.text && props.text.Actiune}</th>
                    </tr>
                </thead>
                <tbody>
                    {istoric}
                </tbody>
            </table>
        </div>
    )
}

export default HistoryTable
