import React, { useState } from 'react'
import './SendEmailModal.css'
import SimpleButton from '../../Reusable/SimpleButton/SimpleButton'
import ModalsActions from '../../../actions/ModalsActions';
import FloatingLableInput from '../../Reusable/FloatingLabelInput/FloatingLableInput';
import AdminActions from '../../../actions/AdminActions';

const SendEmailModal = ({emailId}) => {
  const [email, setEmail] = useState('')

  const handleCloseClick = () => {
    ModalsActions.hideModal()
  }
  
  const handleSendEmail = () => {
    AdminActions.sendEmailNewUser(emailId, email)
    ModalsActions.hideModal()
  }

  return (
    <div className='modal-dialog sendEmailMContainer'>
      <div className="modal-content sendEmailModal">
        <div className="row closeBtn">
          <button type="button" className="modals-close-button close" onClick={handleCloseClick}>&times;</button>
        </div>
        <div className='newEmailInput'>
          <FloatingLableInput label='Email' value={email} name='email' placeholder='Enter new email' onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <div className='confirmButtons'>
          <SimpleButton styleCss='noConfirm' text="Nu, intoarce-te" onClick={handleCloseClick}/>
          <SimpleButton text="Trimite email" onClick={handleSendEmail} />
        </div>
      </div>
    </div>
  )
}

export default SendEmailModal
