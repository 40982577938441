import React, { useState } from 'react'
import './CustomDropdown.css'
import downArrow from '../../Icons/down-arrow.png'

const CustomDropdown = ({ options, selectedOption, handleOptionChange, placeholder, styleCss }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className={`dropdown ${isVisible ? 'visible' : ''} ${styleCss}`}>
      <div className="dropdown-header" onClick={() => setIsVisible(!isVisible)}>
        {selectedOption || placeholder}
        <img src={downArrow} alt='dropdown'/>
      </div>
      <ul className="dropdown-options">
        {options.map((option, ix) => (
          <li key={ix} onClick={() => {handleOptionChange(option); setIsVisible(!isVisible)}} className={option === selectedOption ? 'selected' : ''}>
              {option}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CustomDropdown