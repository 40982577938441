import React from 'react'
import './ToggleButton.css'

const ToggleButton = ({ text, toggled, onChange, styles='' }) => {

  return (
    <div className={`toggle-container ${styles}`}>
      <span>{text}</span>
      <div className={`toggle-button ${toggled ? 'active' : ''}`} onClick={onChange}>
        <div className={`slider ${toggled ? 'active' : ''}`} />
      </div>
    </div>
  );
}

export default ToggleButton